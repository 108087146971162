<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        {{ $t("chartsAdvance.Alertmsg") }}
      </div>
    </b-alert>

    <div
      class="card card-custom bg-gray-100 gutter-b card-stretch card-shadowless"
    >
      <!--begin::Header-->
      <div class="card-header h-auto border-0">
        <!--begin::Title-->
        <div class="card-title py-5">
          <h3 class="card-label">
            <span class="d-block text-dark font-weight-bolder">
              {{ $t("Reports.alexa.title") }}
            </span>
            <span class="d-block text-dark-50 mt-2 font-size-sm">
              {{ $t("Reports.alexa.description") }}
            </span>
          </h3>
        </div>
        <div class="card-toolbar"></div>
        <!--end::Toolbar-->
      </div>
      <div class="card-body">
        <div>
          <b-table striped hover :items="items"></b-table>
        </div>
        <!--end::Example-->
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { FETCH_ALEXA_REPORT,STORE_ALEXA_REPORT } from "@/core/services/store/reports.module";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      items: [],
      unsubscribe: null,
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  components: {},
  beforeDestroy() {
    // Unsubscribe from the store when the component is destroyed
    if (this.unsubscribe) {
      this.unsubscribe();console.log("kill_subscribe");
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === STORE_ALEXA_REPORT && state.reports.acurite) {
        // Update items when STORE_ACURITE_REPORT mutation is committed
        this.items = state.reports.alexa;
      }
    });
    this.$store.dispatch(FETCH_ALEXA_REPORT);
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Alerts" },
    ]);
  },
};
</script>
